<template lang="pug">
	.d-flex.justify-content-center.align-items-center.cursor-pointer.locale-switch(@click="changeLocale")
		.d-flex.locale-switch__country(:style="style")
</template>

<script>
import { mapActions } from 'vuex';
import { localeOptions } from '@/constants/config';

export default {
    name: 'LocaleSwitchSimple',
    props: {
        left: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        localeOptions,
    }),
    methods: {
        ...mapActions(['setLang']),
        changeLocale() {
            this.setLang(_.filter(localeOptions, l => l.id != this.$i18n.locale)[0].id);
            window.location.reload();
        },
    },
    computed: {
        style() {
            return { 'background-image': `url('/assets/img/current/${this.$i18n.locale}.svg')` };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.locale-switch {
    width: 50px;
    height: 32px;
    border-radius: var(--border-radius-rounded);
    background-color: var(--brand-bg-primary);

    &__country {
        width: 20px;
        height: 15px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.small-locale-switch {
        width: 39px;
        height: 24px;
    }
}
</style>
